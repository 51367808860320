<template>
  <v-row justify="start">
    <v-dialog v-model="dialogCrop" scrollable persistent max-width="600px">
      <v-card class="radius-card" dark>
        <v-toolbar dense flat dark>
          <!-- <span class="headline">Tulis rekomendasi</span> -->
          <v-spacer></v-spacer>
          <v-btn fab x- small depressed @click="$emit('close')"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text class="pa-2">
          <div class="" style="width: 100%">
            <vue-croppie
              ref="croppieRef"
              :enableOrientation="true"
              :enableResize="false"
              :boundary="{ width: 300, height: 300 }"
              :viewport="{ width: 250, height: 250 }"
            >
            </vue-croppie>
          </div>
          <!-- <div class="circle__ white"></div> -->
        </v-card-text>
        <Waiting v-if="loading" />
        <notifications group="validate" class="mt-2" position="top center" />
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn dark outlined rounded @change="croppie($event)">
            <div class="upload-btn-wrapper add-width">
              <div class="pa-1">
                <v-icon>mdi-upload</v-icon> Upload Photo
                <input type="file" name="myfile" />
              </div>
            </div>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="white" outlined rounded @click="crop"
            >Save <v-icon>mdi-check</v-icon></v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Swal from "sweetalert2";
import Waiting from "../../../../components/_base/Modal/waiting.vue";

export default {
  name: "croppublc",

  props: ["dialogCrop", "id"],
  components: { Waiting },

  data() {
    return {
      loading: false,
      croppieImage: "",
      cropped: null
    };
  },

  methods: {
    croppie(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      var reader = new FileReader();
      reader.onload = e => {
        this.$refs.croppieRef.bind({
          url: e.target.result
        });
      };

      reader.readAsDataURL(files[0]);
    },
    crop() {
      let options = {
        type: "base64",
        size: { width: 250, height: 250 },
        format: "jpeg"
      };
      this.$refs.croppieRef.result(options, output => {
        this.changePicture(output);
      });
    },

    changePicture(pic) {
      this.uploading = true;
      const data = new FormData();
      data.append("id", this.id);
      data.append("photo", pic);
      this.$store.dispatch("cust/updateUser", data).then(() => {
        this.uploading = false;
        this.$emit("close");
        this.$emit("refetch");
        Swal.fire({
          title: "Berhasil mengubah foto",
          icon: "success",
          showConfirmButton: false,
          timer: 1000,
          position: "top",
          toast: true
        });
      });
    },
    alert(type, title) {
      this.$notify({
        group: "validate",
        type: type,
        title: title,
        duration: 5000
      });
    }
  }
};
</script>

<style scoped>
.add-width {
  width: 170px;
}
.temp {
  position: relative;
}
.over {
  position: absolute;
  margin-top: -5px;
  margin-right: -5px;
}
.img-temp__ {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  /* border: 1px black dotted; */
}
.bg {
  background: #ffff;
}
.circle__ {
  width: 200px;
  height: 200px;
  border-radius: 50%;
}
</style>
