<template>
  <div class="pa-3">
    <v-row>
      <v-col cols="12">
        <v-text-field
          label="Nama lengkap"
          v-model="profile.nama_lengkap"
          required
          dense
          solo
          hide-details="auto"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-select
          v-if="listProvince"
          :options="listProvince"
          :reduce="item => item.id"
          v-model="selectedProvince"
          @search="query => (searchProvince = query)"
          label="nama_provinsi"
          placeholder="Province"
          class="white"
          @input="getCity(selectedProvince)"
        ></v-select>
      </v-col>
      <v-col cols="6">
        <v-select
          v-if="listCity"
          :options="listCity"
          :reduce="item => item.id"
          v-model="selectedCity"
          @search="query => (searchCity = query)"
          label="nama_kota"
          placeholder="City"
          class="white"
        ></v-select>
        <v-select
          v-else
          placeholder="City"
          v-model="selectedCity"
          :loading="loadCity"
          class="white"
        >
          <span slot="no-options" @click="$refs.select.open = false">
            pilih provinsi terlebih dahulu
          </span>
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          label="Phone"
          v-model="profile.no_hp"
          required
          dense
          solo
          hide-details="auto"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">Jenis Kelamin</v-col>
      <v-col cols="8">
        <v-radio-group
          v-model="profile.jenis_kelamin"
          hide-details="auto"
          dense
          class="ma-0 pa-0"
          row
        >
          <v-radio label="Laki-laki" value="laki-laki"></v-radio>
          <v-radio label="Perempuan" value="Perempuan"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          label="Tempat Lahir"
          v-model="profile.tempat_lahir"
          required
          dense
          solo
          hide-details="auto"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-menu
          v-model="calendar"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="profile.tanggal_lahir.tanggal"
              label="Birth Date"
              append-icon="mdi-calendar"
              readonly
              dense
              v-bind="attrs"
              v-on="on"
              hide-details="auto"
              solo
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="profile.tanggal_lahir.tanggal"
            @input="calendar = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <div class="my-3">
      <v-btn
        color="purple"
        v-if="!loading"
        @click="updateData"
        dark
        block
        class="text-capitalize mt-5"
      >
        simpan
      </v-btn>
      <v-btn color="purple" v-else dark block class="text-capitalize mt-5">
        loading...
      </v-btn>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "formIntreoPublic",
  props: ["profile"],
  components: {
    vSelect
  },
  data() {
    return {
      listCity: null,
      listProvince: null,
      selectedProvince: null,
      selectedCity: null,
      searchProvince: "",
      searchCity: "",
      calendar: false,
      loadCity: false,
      loading: false
    };
  },
  mounted() {
    this.$store.dispatch("area/publicProvince").then(data => {
      console.log(data.data);
      this.listProvince = data.data;
      let kota = this.profile.kota_id;
      if (kota) {
        this.selectedCity = kota;
        this.getCity(kota);
      }
    });
  },
  methods: {
    getCity(id) {
      this.loadCity = true;
      this.$store.dispatch("area/filterCity", id).then(data => {
        this.loadCity = false;
        this.listCity = data.data;
      });
    },
    updateData() {
      this.loading = true;
      let form = {
        id: this.profile.id,
        nama_lengkap: this.profile.nama_lengkap,
        jenis_kelamin: this.profile.jenis_kelamin,
        tempat_lahir: this.profile.tempat_lahir,
        tanggal_lahir: this.profile.tanggal_lahir.tanggal,
        kota_id: this.selectedCity,
        no_hp: this.profile.no_hp
      };
      this.$store.dispatch("cust/updateUser", form).then(data => {
        this.loading = false;
        console.log(data);
        this.$emit("refeth");
        this.$emit("close");
        Swal.fire({
          title: "Update Profile Berhasil",
          icon: "success",
          showConfirmButton: false,
          timer: 1000,
          position: "top",
          toast: true
        });
      });
    }
  }
};
</script>

<style></style>
