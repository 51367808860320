<template>
  <div class="_bg-default _80w _100vh __profile">
    <div class="_100top _60-w pa-3" v-if="profile">
      <!-- INTRO -->
      <v-card class="radius-card">
        <v-img src="../../../assets/img/meptest.jpeg" height="120px"> </v-img>
        <div class="pa-3 d-flex justify-space-between">
          <v-hover>
            <template v-slot:default="{ hover }">
              <v-avatar color="white" size="150" class="min-img">
                <v-img
                  :src="profile.photo ? profile.photo : dummy"
                  class="img-fit"
                  alt="asd"
                >
                  <v-fade-transition>
                    <v-overlay v-if="hover" absolute color="#036358">
                      <v-btn icon @click="dialogCrop = true"
                        ><v-icon>mdi-camera</v-icon></v-btn
                      >
                    </v-overlay>
                  </v-fade-transition>
                </v-img>
              </v-avatar>
            </template>
          </v-hover>
          <v-btn
            icon
            depressed
            small
            outlined
            color="cyan"
            content="Edit Profile"
            v-tippy="{
              arrow: true,
              arrowType: 'round',
              animation: 'fade',
              theme: 'light',
              maxWidth: 150
            }"
            v-if="!updateIntro"
            @click="updateIntro = true"
          >
            <v-icon dark small> mdi-pencil </v-icon>
          </v-btn>
          <v-btn
            icon
            depressed
            small
            outlined
            color="red"
            content="Edit Profile"
            v-tippy="{
              arrow: true,
              arrowType: 'round',
              animation: 'fade',
              theme: 'light',
              maxWidth: 150
            }"
            v-if="updateIntro"
            @click="updateIntro = false"
          >
            <v-icon dark small> mdi-close </v-icon>
          </v-btn>
        </div>
        <div v-if="!updateIntro">
          <div class="px-3 d-flex justify-space-between">
            <div>
              <h3>{{ profile.nama_lengkap }}</h3>
            </div>
          </div>
          <div class="pa-2">
            <v-row>
              <v-col cols="12" md="5">
                <v-col cols="12" md="12" class="py-0 grey--text"
                  ><v-icon>mdi-clipboard-account</v-icon> Username</v-col
                >
                <v-col cols="12" md="12" class="py-0">{{
                  profile.username
                }}</v-col>
              </v-col>
              <v-col cols="12" md="7">
                <v-col cols="12" md="12" class="py-0 grey--text"
                  ><v-icon>mdi-email</v-icon> E-mail</v-col
                >
                <v-col cols="12" md="12" class="py-0">{{
                  profile.email
                }}</v-col>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="5">
                <v-col cols="12" md="12" class="py-0 grey--text"
                  ><v-icon>mdi-cellphone</v-icon> Phone</v-col
                >
                <v-col cols="12" md="12" class="py-0">{{
                  profile.no_hp ? profile.no_hp : "-"
                }}</v-col>
              </v-col>
              <v-col cols="12" md="7">
                <v-col cols="12" md="12" class="py-0 grey--text"
                  ><v-icon>mdi-calendar-today</v-icon> Tanggal Lahir</v-col
                >
                <v-col cols="12" md="12" class="py-0">{{
                  profile.tanggal_lahir.tanggal
                    ? profile.tanggal_lahir.tanggal
                    : "-"
                }}</v-col>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="5">
                <v-col cols="12" md="12" class="py-0 grey--text"
                  ><v-icon>mdi-gender-male-female</v-icon>Gender</v-col
                >
                <v-col cols="12" md="12" class="py-0">{{
                  profile.jenis_kelamin
                }}</v-col>
              </v-col>
              <v-col cols="12" md="7">
                <v-col cols="12" md="12" class="py-0 grey--text"
                  ><v-icon>mdi-map-marker</v-icon>Tempat Lahir</v-col
                >
                <v-col cols="12" md="12" class="py-0">{{
                  profile.tempat_lahir ? profile.tempat_lahir : "-"
                }}</v-col>
              </v-col>
            </v-row>
          </div>
        </div>
        <div v-if="updateIntro">
          <form-intro
            v-bind:profile="profile"
            @refetch="fetch"
            @close="updateIntro = false"
          />
        </div>
      </v-card>
      <div class="component_modal">
        <crop-photo
          v-bind:dialogCrop="dialogCrop"
          v-bind:id="profile.id"
          @refetch="fetch"
          @close="dialogCrop = false"
        />
      </div>
    </div>

    <!-- SIDE RIGHTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTT -->
    <!-- <div class="__side _10w _100vh" v-if="profile">
      <v-card class="radius-card mt-1">
        <div>
          <v-tabs
            v-model="tab"
            class="mb-5 rounded"
            background-color="purple lighten-5"
            color="purple"
            fixed-tabs
          >
            <v-tab href="#tab-1">Transaction</v-tab>
            <v-tab href="#tab-2">History Counseling</v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab" style="background: none">
            <v-tab-item value="tab-1"> </v-tab-item>
            <v-tab-item value="tab-2"> </v-tab-item>
            <v-tab-item value="tab-3"> </v-tab-item>
          </v-tabs-items>
        </div>
      </v-card>
    </div> -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import FormIntro from "./components/formIntro.vue";
import CropPhoto from "./Modal/cropPhoto.vue";

export default {
  name: "Personal",
  computed: {
    ...mapState({
      id: state => state.id,
      env: state => state.API_URL,
      profile: state => state.cust.myProfile,
      dummy: state => state.dummy
    })
  },
  components: { FormIntro, CropPhoto },
  data() {
    return {
      loading: false,
      dataCV: null,
      slide: 0,
      dialogCrop: false,
      tab: null,
      dataAbout: "",
      edited: false,
      updateIntro: false
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.loading = true;
      this.$store
        .dispatch("cust/myProfile")
        .then(() => {
          this.loading = false;
        })
        .catch(err => {
          console.log(err);
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped>
/* div >>> iframe {
  height: 200px;
  width: 100%;
} */
.hover__and__pick {
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.5 all;
}

.hover__and__pick:hover {
  background: rgb(220, 217, 217);
  transition: 0.5 all;
}
.__side {
  margin-top: 110px;
}
.__profile {
  display: flex;
}
._60-w {
  width: 60%;
}
._10w {
  padding: 0 20px 0 0;
  width: 40%;
}
.min-img {
  margin-top: -120px;
  border: 2px white solid;
}
@media (max-width: 576px) {
  .__side {
    padding: 20px;
    margin-top: 20px;
  }
  .__profile {
    display: block;
  }
  ._60-w {
    width: 100%;
  }
  ._10w {
    width: 100%;
    /* padding-right: 20px; */
  }
}
@media (max-width: 768px) {
  .__side {
    padding: 20px;
    margin-top: 20px;
  }
  .__profile {
    display: block;
  }
  ._60-w {
    width: 100%;
  }
  ._10w {
    width: 100%;
    /* padding-right: 20px; */
  }
}
</style>
